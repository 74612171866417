<template>
  <div>    
    <ul class="list-group mb-3">
      <li class="list-group-item lh-condensed">
        <b-row class="d-flex">
          <b-col md="7">
            <h6 class="my-0">{{ group.group }}</h6>            
          </b-col>
          <b-col class="d-flex flex-column align-items-end">
            <!-- <span class="text-muted">{{ toMonetary(plan.total) }}</span> -->
            <small class="text-muted"
              >{{ plan.number }} x {{ toMonetary(plan.value) }}</small
            >
          </b-col>
        </b-row>
      </li>
      <li class="list-group-item lh-condensed" v-if="enrollment.length > 0">
        <b-row>
          <b-col md="7">
            <h6 class="my-0">
              {{ enrollment[0].description }}
            </h6>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-end"
            v-if="this.value.enrollment_value.group_installment_id"
          >
            <!-- <span class="text-muted">
              {{
                toMonetary(
                  enrollmentInstallmentsById[
                    value.enrollment_value.group_installment_id
                  ].discount_amount
                )
              }}</span
            > -->
            <small class="text-muted">
              {{
                get_enrollment_parcel(
                  enrollmentInstallmentsById[
                    value.enrollment_value.group_installment_id
                  ]
                )
              }}
            </small>
          </b-col>
          <b-col v-else class="d-flex flex-column align-items-end">
            <span class="text-muted">
              {{ toMonetary(enrollment[0].value) }}
            </span>
          </b-col>
        </b-row>
      </li>
      <li
        v-for="optionalProduct in value.items"
        :key="optionalProduct.id"
        class="list-group-item lh-condensed"
      >
        <b-row>
          <b-col md="7">
            <h6 class="my-0">
              {{ productsById[optionalProduct.product_id].product }}
            </h6>
            <small class="text-muted">{{
              productsById[optionalProduct.product_id].description
            }}</small>
          </b-col>
          <b-col
            class="d-flex flex-column align-items-end"
            v-if="optionalProduct.group_installment_id"
          >          
            <span class="text-muted">
              {{
                toMonetary(
                  productInstallmentsById[
                    optionalProduct.group_installment_id
                  ][0].discount_amount
                )
              }}</span
            >
            <small class="text-muted">
              {{
                get_product_parcel(
                  productInstallmentsById[
                    optionalProduct.group_installment_id
                  ][0]
                )
              }}
            </small>
          </b-col>
          <b-col class="d-flex flex-column align-items-end" v-else>
            <span class="text-muted">
              {{ toMonetary(productsById[optionalProduct.product_id].total) }}
            </span>
          </b-col>
        </b-row>
      </li>

      <!-- <li class="list-group-item d-flex justify-content-between">
        <span>Total</span>
        <strong>{{ toMonetary(total) }}</strong>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    data() {
      return this.$store.state.confirmReservation.data;
    },
    plan() {
      return this.data.plan;
    },
    group() {
      return this.data.group;
    },
    period() {
      return this.data.period;
    },
    products() {
      return this.data.product;
    },
    productsById() {
      return _.keyBy(this.products, "id");
    },
    groupInstallment(){

      if(value.group_installment_id){

      }
    },
    enrollmentInstallmentsById() {
      const byId = _.keyBy(
        this.enrollment[0].enrollmentinstallment,
        "group_enrollment_installment_id"
      );
      return byId;
    },
    productInstallmentsById() {
      const installments = _.map(this.products, (product) => {
        return product.productinstallment;
      });
      const norm = _.flatten(installments);
      const grouped = _.groupBy(norm, "group_installment_id");

      return grouped;
    },
    enrollment() {
      return this.data.enrollmentValue;
    },
    totalPlan() {
      return this.plan.total * 1;
    },
    totalEnrollment() {
      if (this.enrollment.length > 0) {
        if (this.value.enrollment_value.group_installment_id) {
          const enrollmentValue =
            this.enrollmentInstallmentsById[
              this.value.enrollment_value.group_installment_id
            ];
          return enrollmentValue.discount_amount * 1;
        } else {
          return this.enrollment[0].total * 1;
        }
      } else {
        return 0;
      }
    },
    totalItems() {
      const productsValue = _.map(this.value.items, (item) => {
        if (item.group_installment_id) {
          const itemById =
            this.productInstallmentsById[item.group_installment_id];
          return itemById[0].discount_amount * 1;
        } else {
          return this.productsById[item.product_id].total * 1;
        }
      });      
      const totalProducts = _.reduce(
        productsValue,
        (a, b) => {
          return a + b;
        },
        0
      );
      return totalProducts;
    },
    total() {
      const total = this.totalPlan + this.totalEnrollment + this.totalItems;
      this.value.total = total
      return total
    },
  },
  methods: {
    get_product_parcel(installment) {       
      const value = installment.value;
      return `${installment.number} x ${this.toMonetary(value)}`;
    },
    get_enrollment_parcel(enrollment) {
      console.log('enrollment =>',enrollment)
      const value = enrollment.value;
      return `${enrollment.number} x ${this.toMonetary(value)}`;
    },
  },
};
</script>

<style>
</style>